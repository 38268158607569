/* @jsx jsx */
import { ButtonWithIcon } from "components";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useState } from "react";
import { Button, Flex, Heading, Paragraph, jsx, Box } from "theme-ui";
import {
  AuthContext,
  axpTokenWithFirebase,
  bookingSignIn,
  fetchProfile,
  loyaltySignIn,
} from "../AuthProvider";
import DividerText from "components/DividerText";
import {
  ERROR_NOT_VERIFIED,
  sendVerificationEmail,
  signInApple,
  signInEmail,
  signInGoogle,
  signUpEmail,
  userExist,
} from "../firebase";
import BookingDetailsForm from "./BookingDetailsForm";
import { ArrowBack } from "@material-ui/icons";
import WithEmailForm from "./WithEmailForm";
import FloatingAlert from "components/FloatingAlert";
import ConfirmationEmail from "./ConfrimationEmail";
import LoadingIndicator from "components/LoadingIndicator";
import { useTempState } from "utils";
import WithLoyaltyForm from "./WithLoyaltyForm";
import { LOCALSTORAGE_SINGIN_KEY } from "Authentication/SignInPage";
import { updateAxp } from "utils/axpAxios";

import authConfig from "Authentication/authMethods.json";

const REDIRECT_URL = "/recommendations";

// Titles
const HELLO_TITLE = "Hello!";
const BOOKING_TITLE = "Log in";
const CONFIRMATION_TITLE = "Please check your inbox";
const WITH_LOYALTY_TITLE = "Welcome Fan of M.O.";

// Descriptions
//const MO_FAN_DESCRIPTION = "Are you a fan of M.O.?";
const MO_FAN_DESCRIPTION = "Log in for personalised recommendations.";
const BOOKING_DESCRIPTION =
  "Please complete log in with your booking confirmation number and email.";
const WITH_EMAIL_DESCRIPTION =
  "Please complete log in with your email and password.";
const WITH_LOYALTY_DESCRIPTION =
  "Please log in with either your Member ID or email address for your Fans of M.O. account.";

function titleByStep(step) {
  switch (step) {
    case "choosing":
      return HELLO_TITLE;
    case "booking-details":
      return BOOKING_TITLE;
    case "confirmation-email":
      return CONFIRMATION_TITLE;
    case "with-loyalty":
      return WITH_LOYALTY_TITLE;
    default:
      return HELLO_TITLE;
  }
}

function descriptionByStep(step) {
  switch (step) {
    case "choosing":
      return MO_FAN_DESCRIPTION;
    case "booking-details":
      return BOOKING_DESCRIPTION;
    case "confirmation-email":
      return "";
    case "with-email":
      return WITH_EMAIL_DESCRIPTION;
    case "with-loyalty":
      return WITH_LOYALTY_DESCRIPTION;
    default:
      return BOOKING_DESCRIPTION;
  }
}

function formatErrorMessage(e) {
  return {
    title: "Something went wrong",
    description:
      e?.message ||
      "Please check your details. Having issues? Please contact us.",
  };
}

// const fetchAuthConfiguration = async () => {
//   return axpAxios.get(
//     `/open_api/v1/brands/${getBrandId()}/auth_configuration`,
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${getOpenApi()}`,
//       },
//     }
//   );
// };

const AuthForm = ({ redirectUrl = REDIRECT_URL }) => {
  const [step, setStep] = useState("choosing");
  const [email, setEmail] = useState("");

  const [error, setError] = useTempState();
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const { isSignedIn, signIn } = useContext(AuthContext);

  useEffect(() => {
    if (isSignedIn) {
      localStorage.removeItem(LOCALSTORAGE_SINGIN_KEY);
      navigate(redirectUrl || REDIRECT_URL);
    }
  }, [isSignedIn]);

  const catchError = (e) => {
    setError(formatErrorMessage(e));
    setLoading(false);
  };

  const authWithProvider = (provider) => () => {
    setLoading(true);
    provider()
      .then(axpTokenWithFirebase)
      .then(fetchProfile)
      .then(signIn)
      .then(() => {
        localStorage.removeItem(LOCALSTORAGE_SINGIN_KEY);
        navigate(redirectUrl || REDIRECT_URL);
      })
      .catch(catchError)
      .finally(() => setLoading(false));
  };

  const emailSubmit = async ({ email, password }) => {
    setLoading(true);

    try {
      const data = {
        email,
        password,
      };
      if (await userExist(data.email)) {
        signInEmail({
          ...data,
        })
          .then(axpTokenWithFirebase)
          .then(fetchProfile)
          .then(signIn)
          .then(() => {
            localStorage.removeItem(LOCALSTORAGE_SINGIN_KEY);
            navigate(redirectUrl || REDIRECT_URL);
          })
          .catch((e) => {
            if (e.message === ERROR_NOT_VERIFIED) {
              setStep("confirmation-email");
              setError("ERROR_NOT_VERIFIED");
              setEmail(email);
              return;
            }
            catchError(e);
          })
          .finally(() => setLoading(false));
      } else {
        signUpEmail(data)
          .then(() => {
            setStep("confirmation-email");
            setEmail(email);
          })
          .catch(catchError)
          .finally(() => setLoading(false));
      }
    } catch (e) {
      catchError(e);
    }
  };

  const bookingSubmit = (data) => {
    setLoading(true);
    updateAxp();
    bookingSignIn(data)
      .then(signIn)
      .catch(catchError)
      .finally(() => setLoading(false));
  };

  const withLoyaltySubmit = async (data) => {
    setLoading(true);
    updateAxp();
    loyaltySignIn(data)
      .then(signIn)
      .catch(catchError)
      .finally(() => setLoading(false));
  };

  const resendEmail = () => {
    setLoading(true);

    sendVerificationEmail()
      .then(() => {
        setInfo({ description: "Your confirmation email was sent" });
        setTimeout(() => {
          setInfo(null);
        }, 3000);
      })
      .catch(catchError)
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <Flex
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
          background: "background",
        }}
      >
        <LoadingIndicator />
        {authConfig && (
          <Paragraph variant="subtitle1" sx={{ textAlign: "center", mt: 4 }}>
            Checking details...
          </Paragraph>
        )}
      </Flex>
    );
  }

  return (
    <>
      <Heading as="h4" sx={{ variant: "text.heading4", mb: "8px" }}>
        {titleByStep(step)}
      </Heading>
      <Paragraph sx={{ variant: "text.body1", mb: "40px" }}>
        {descriptionByStep(step)}
      </Paragraph>
      {["confirmation-email", "with-email", "with-loyalty"].includes(step) && (
        <ArrowBack
          sx={{ mt: "-20px", mb: "20px" }}
          onClick={() => {
            setStep("choosing");
          }}
        />
      )}
      <FloatingAlert
        variant="success"
        data={info}
        isOpen={!!info}
        onClose={() => {
          setInfo(null);
        }}
      />
      <FloatingAlert
        variant="error"
        data={error}
        isOpen={!!error}
        onClose={() => {
          setError(null);
        }}
      />
      {step === "choosing" && (
        <>
          {authConfig && authConfig.includes("loyalty") && (
            <>
              <Button
                variant="outlined"
                onClick={() => setStep("with-loyalty")}
              >
                Continue with Fans of M.O.
              </Button>
              {authConfig.length > 0 && (
                <DividerText text="or" styles={{ mb: 10, mt: 12 }} />
              )}
            </>
          )}
          {authConfig && (
            <Flex sx={{ flexDirection: "column", gap: 4 }}>
              {authConfig && authConfig.includes("booking") && (
                <Button
                  sx={{ variant: "buttons.outlined" }}
                  onClick={() => setStep("booking-details")}
                >
                  Continue with Booking Details
                </Button>
              )}
              {authConfig.includes("apple") && (
                <ButtonWithIcon
                  icon={
                    <StaticImage
                      src="../../images/apple.svg"
                      placeholder="none"
                      width={24}
                      height={24}
                    />
                  }
                  variant="buttons.blackOutlined"
                  onClick={authWithProvider(signInApple)}
                >
                  Continue with Apple
                </ButtonWithIcon>
              )}
              {authConfig.includes("google") && (
                <ButtonWithIcon
                  icon={
                    <StaticImage
                      src="../../images/google.svg"
                      placeholder="none"
                      width={24}
                      height={24}
                    />
                  }
                  variant="buttons.blackOutlined"
                  onClick={authWithProvider(signInGoogle)}
                >
                  Continue with Google
                </ButtonWithIcon>
              )}

              {authConfig.includes("email_password") && (
                <ButtonWithIcon
                  icon={
                    <StaticImage
                      src="../../images/email.svg"
                      placeholder="none"
                      width={24}
                      height={24}
                    />
                  }
                  variant="buttons.blackOutlined"
                  onClick={() => setStep("with-email")}
                >
                  Continue with Email
                </ButtonWithIcon>
              )}
            </Flex>
          )}
        </>
      )}
      {step === "booking-details" && (
        <BookingDetailsForm
          onSubmit={bookingSubmit}
          prevStep={() => setStep("choosing")}
        />
      )}
      {step === "with-email" && <WithEmailForm onSubmit={emailSubmit} />}
      {step === "confirmation-email" && (
        <ConfirmationEmail
          email={email}
          onContinue={() => setStep("with-email")}
          resendEmail={resendEmail}
        />
      )}
      {step === "with-loyalty" && (
        <WithLoyaltyForm onSubmit={withLoyaltySubmit} />
      )}
    </>
  );
};

export default AuthForm;
