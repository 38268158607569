import { mix } from "@theme-ui/color";

export const basePallet = {
  grey: {
    0: "#FFFFFF",
    50: "#FEFCF9",
    100: "#FAF8F5",
    200: "#F4F1ED",
    300: "#EDE9E4",
    400: "#E0DBD3",
    500: "#C5BDB1",
    600: "#AA9F8F",
    700: "#8F816D",
    800: "#60523E",
    900: "#310E0E",
    1000: "#121212",
  },
};

export const basePalletDark = {
  grey: {
    0: "#121212",
    50: "#31220E",
    100: "#60523E",
    200: "#8F816D",
    300: "#AA9F8F",
    400: "#C5BDB1",
    500: "#E0DBD3",
    600: "#EDE9E4",
    700: "#F4F1ED",
    800: "#FAF8F5",
    900: "#FEFCF9",
    1000: "#FEFCF9",
  },
};

export const colors = {
  ...basePallet,
  text: {
    __default: "#31140EDE",
    heading: "#31140E",
    secondary: "#31220E99",
    disabled: "#311F0E61",
  },

  // Primary
  primary: {
    __default: "#A06B22",
    dark: "#724C18",
    light: "#D49135",
    contrastText: basePallet.grey[0],
    hover: "#A06B220A",
    active: "#A06B223D",
    selected: "#A06B2214",
    border: "#A06B2280",
    activeMix: mix("#A06B22", "#FFFFFF", 0.24),
  },

  // Info
  info: {
    __default: "#185172",
    dark: "#0E3043",
    light: "#257BAC",
    darkText: "#091E2A",
    lightBackground: "#EEF7FB",
  },

  // Error
  error: {
    __default: "#C04242",
    dark: "#993333",
    light: "#D37A7A",
    contrastText: basePallet.grey[0],
    darkText: "#4D1A1A",
    lightBackground: "#FAF0F0",
  },

  // Warning
  warning: {
    __default: "#DEAD42",
    dark: "#C39022",
    light: "#E8C67D",
    contrastText: basePallet.grey[1000],
    darkText: "#56441D",
    lightBackground: "#56441D",
  },

  // Success
  success: {
    __default: "#3C6F3E",
    dark: "#294D2B",
    light: "#569F5A",
    contrastText: basePallet.grey[0],
    darkText: "#182C19",
    lightBackground: "#F0F4F0",
  },

  shadows: {
    elevation1dp: "#3427161F",
    elevation3dp: "#34271633",
    elevation5dp: "#34271647",
  },

  // Other
  background: basePallet.grey[50],
  componentBG1: "#FFFFFF",
  componentBG2: "#FFFFFF",
  componentBG3: "#FFFFFF",
  backgroundDisabled: "#F4F1ED",
  lineDetail: "#31220E33",
  systemBlack: basePallet.grey[1000],
  systemWhite: basePallet.grey[0],
  backdrop: "#12121289",

  menuSliderGradientLeft: `linear-gradient(90deg, ${basePallet.grey[50]} 0%, ${basePallet.grey[50]}00 100%)`,
  menuSliderGradientRight: `linear-gradient(270deg, ${basePallet.grey[50]} 0%, ${basePallet.grey[50]}00 100%)`,

  modes: {
    dark: {
      ...basePalletDark,
      text: {
        __default: "#FFFFFFDE",
        heading: "#FFFFFF",
        secondary: "#FFFFFF99",
        disabled: "#FFFFFF61",
      },

      background: basePalletDark.grey[0],

      // Primary
      primary: {
        __default: "#E3B87D",
        dark: "#F0D8B8",
        light: "#D99F4F",
        contrastText: basePalletDark.grey[0],
        hover: "#E3B87D0A",
        active: "#E3B87D3D",
        selected: "#E3B87D14",
        border: "#E3B87D80",
      },

      // Info
      info: {
        __default: "#93E6F1",
        dark: "#D2F5F9",
        light: "#62DAEA",
        contrastText: basePalletDark.grey[0],
        darkText: "#E7F9FC",
        lightBackground: "#242929",
      },

      error: {
        __default: "#FF8A80",
        dark: "#FFCBC7",
        light: "#FF5647",
        contrastText: basePalletDark.grey[0],
        darkText: "#FFD0CC",
        lightBackground: "#2A1E1D",
      },

      warning: {
        __default: "#FFE57F",
        dark: "#FFF4C7",
        light: "#FFDA47",
        contrastText: basePalletDark.grey[0],
        darkText: "#FFF5CC",
        lightBackground: "#2A271D",
      },

      success: {
        __default: "#69F082",
        dark: "#ACF7B8",
        light: "#3CEC5C",
        contrastText: basePalletDark.grey[0],
        darkText: "#C3F9CD",
        lightBackground: "#1B281E",
      },

      shadows: {
        elevation1dp: "#1212121F",
        elevation3dp: "#12121233",
        elevation5dp: "#12121247",
      },

      // Other
      background: basePalletDark.grey[0],
      componentBG1: "#1D1913",
      componentBG2: "#292015",
      componentBG3: "#342716",
      backgroundDisabled: "#8F816D",
      lineDetail: "#FFFFFF33",
      systemWhite: "#FFFFFF",
      systemBlack: "#121212",
      backdrop: "#1212128A",
      menuSliderGradientLeft: `linear-gradient(90deg, ${basePalletDark.grey[0]} 0%, ${basePalletDark.grey[0]}00 100%)`,
      menuSliderGradientRight: `linear-gradient(270deg, ${basePalletDark.grey[0]} 0%, ${basePalletDark.grey[0]}00 100%)`,
    },
  },
};

export const shadows = {
  elevation1dp: "0px 2px 12px 0px var(--theme-ui-colors-shadows-elevation1dp)",
  elevation3dp: "0px 8px 24px 0px var(--theme-ui-colors-shadows-elevation3dp)",
  elevation5dp: "0px 16px 40px 0px var(--theme-ui-colors-shadows-elevation5dp)",
};
